@import "../styles/helpers";
@import "../styles/breakpoints";

.projects {
  &__outer-wrapper {
    padding: 40vh 0;
    width: 70%;
    margin: 0 15%;
    min-height: calc(var(--app-height) - 80vh);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: relative;
  }

  &__media-wrapper {
    position: fixed;
    top: 20px;
    left: 90px;
    pointer-events: none;
    height: calc(var(--app-height) - 40px);
    width: calc(100% - 180px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    @include hover {
      transform: scale(1.1);
      text-decoration: none;
      z-index: 2;
    }
  }

  &__media-image,
  &__media-video {
    display: none;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    &.active {
      display: block;
    }
  }

  &__project-title {
    font-size: 4vw;
    line-height: 2.4vw;
    transition: all 0.2s ease-in-out;
    margin-bottom: 50px;
    @include hover {
      transform: scale(1.1);
      text-decoration: none;
      z-index: 3;
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }

  &__project-client {
    font-size: 24px;
    line-height: 24px;
  }

  &__filters-wrapper {
    margin: 20px;
    z-index: 1001;
    position: fixed;
    top: 0;
    left: 0;
    text-align: left;
  }
  &__filter-item {
    display: block;
    &.disabled {
      pointer-events: none;
      opacity: 0.6;
    }
  }
}

@include mobile {
  .projects {
    &__project-title {
      font-size: 30px;
      line-height: 30px;
    }
    &__project-client {
        font-size: 18px;
        line-height: 22px;
      }
  }
}
