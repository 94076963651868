// --------------------------------------------------------------
// Badges
// --------------------------------------------------------------

.plyr__badge {
  background: $plyr-badge-background;
  border-radius: $plyr-badge-border-radius;
  color: $plyr-badge-text-color;
  font-size: $plyr-font-size-badge;
  line-height: 1;
  padding: 3px 4px;
}
