// @font-face {
//   font-family: "Diatype Extended";
//   src: url("../assets/fonts/Diatype.eot");
//   src: url("../assets/fonts/Diatype.eot?#iefix")
//       format("embedded-opentype"),
//     url("../assets/fonts/Diatype.woff") format("woff"),
//     url("../assets/fonts/Diatype.ttf") format("truetype"),
//     url("../assets/fonts/Diatype.svg#Diatype")
//       format("svg");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }
@font-face {
	font-family: 'WhyteInktrap-Regular';
	src: url('../assets/fonts/WhyteInktrap-Regular.woff') format('woff'),
	url('../assets/fonts/WhyteInktrap-Regular.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

html,
body {
  font-family: 'WhyteInktrap-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--orange);
  font-size: 24px;
  line-height: 30px;
}

a{
  text-decoration: none;
  color: inherit;
  @include hover{
    color: inherit;
    text-decoration: underline;
  }
}

@include mobile{
  html, body{
    font-size: 18px;
    line-height: 22px;
  }
  
}