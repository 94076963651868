*::selection {
  background: var(--orange);
  color: #fff;
}
*::-moz-selection {
  background: var(--orange);
  color: #fff;
}

*::-webkit-scrollbar {
  width: 3px;
}
*::-webkit-scrollbar-track {
  background-color: #FFF;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--orange);
  border: none;
}

html,
body {
  width: 100%;
  height: 100%;
}

button {
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-transform: inherit;
  border: 0;
  margin: 0;
  padding: 0;
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  @include hover{
    text-decoration: underline;
  }
}

.loading-dot{
	width: 20px;
	height: 20px;
	margin: 15px;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 1000;
	background-color: var(--orange);
	border-radius: 50%;
	animation: pulse 0.5s linear infinite;
}

.error {
  &__outer-wrapper {
    width: 80%;
    margin: 0 10%;
    height: var(--app-height);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}
