@import "../../styles/breakpoints";

.nav{

    &__vertical-wrapper{
        height: var(--app-height);
        width: 24px;
        margin: 0 15px;
        position: fixed;
        z-index: 1000;
        &.left{
            left: 0;
        }
        &.right{
            right: 0;
        }
    }

    &__rotated{
        position: absolute;
        top: 50%;
        left: 50%;
        white-space: nowrap;
        text-align: center;
        transform-origin: center;
        &.left{
            transform: translateX(-50%) rotate(-90deg);
        }
        &.right{
            transform: translateX(-50%) rotate(90deg);
        }
    }
}

@include mobile{
    .nav{

        &__vertical-wrapper{
            width: 18px;
            margin: 0 10px;
        }
    }

}