@import "../../styles/helpers";
@import "../../styles/breakpoints";

.gallery {
  &__outer-wrapper {
    width: calc(100% - 142px);
    height: calc(var(--app-height) - 300px);
    margin: 150px 0;
    padding: 0 70px;

    position: absolute;
    top: 0;
    left: 0;
  }

  &__arrow {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    font-size: 4vw;
    @include hover {
      font-size: 5vw;
      text-decoration: none;
    }
    position: absolute;
    top: 0;
    z-index: 2;
    &.prev {
      left: 60px;
    }
    &.next {
      right: 60px;
      text-align: right;
    }
  }

  &__media-wrapper{
    width: calc(100% - 50px);
    height: calc(var(--app-height) - 300px);
    margin: 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // background-color: blue;
  }

  &__image{
    display: block;
    margin: 0 auto;
    max-height: calc(var(--app-height) - 300px);
    max-width: 100%;
    width: auto;
  }

  &__video-outer{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  // &__video-inner{
    // position: absolute;
    // top: 0;
    // left: 0;
    // max-width: 100%;
    // max-height: 100%;
  //   height: 100%;
  //   width: 100%;
  // }
}

.swiper-slide{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@include mobile{
    .gallery {
        &__outer-wrapper {
          width: calc(100% - 80px);
          padding: 0 40px;
        }
      
        &__arrow {
          font-size: 10vw;
          @include hover {
            font-size: 12vw;
          }
          &.prev {
            left: 40px;
          }
          &.next {
            right: 40px;
          }
        }
      
        &__media-wrapper{
          width: 100%;
          margin: 0 ;
        }
      
      }
      
  
  }