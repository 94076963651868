// ==========================================================================
// Colors
// ==========================================================================

$plyr-color-main: var(--plyr-color-main, #F4512C) !default;
$plyr-video-background: var(--plyr-video-background, rgb(0 0 0 / 100%)) !default;

// Grayscale
$plyr-color-gray-900: hsl(216deg 15% 16%) !default;
$plyr-color-gray-800: hsl(216deg 15% 25%) !default;
$plyr-color-gray-700: hsl(216deg 15% 34%) !default;
$plyr-color-gray-600: hsl(216deg 15% 43%) !default;
$plyr-color-gray-500: hsl(216deg 15% 52%) !default;
$plyr-color-gray-400: hsl(216deg 15% 61%) !default;
$plyr-color-gray-300: hsl(216deg 15% 70%) !default;
$plyr-color-gray-200: hsl(216deg 15% 79%) !default;
$plyr-color-gray-100: hsl(216deg 15% 88%) !default;
$plyr-color-gray-50: hsl(216deg 15% 97%) !default;
