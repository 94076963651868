// --------------------------------------------------------------
// Volume
// --------------------------------------------------------------

.plyr__volume {
  align-items: center;
  display: flex;
  position: relative;

  input[type='range'] {
    margin-left: calc(#{$plyr-control-spacing} / 2);
    margin-right: calc(#{$plyr-control-spacing} / 2);
    max-width: 90px;
    min-width: 60px;
    position: relative;
    z-index: 2;
  }
}
