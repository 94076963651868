@import "../../styles/helpers";

.home-desktop{

    &__item-wrapper{
        width: 25%;
        height: auto;
        box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.56);
        position: fixed;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.4s ease-in-out;
        cursor: grab;
        &:active{
            cursor: grabbing;
        }
        &.active{
            opacity: 1;
            pointer-events: all;
        }
        @include hover{
            .home-desktop__link{
                opacity: 1;
            }
        }
    }

    &__media{
        display: block;
        width: 100%;
        height: auto;
        pointer-events: none;
    }

    &__link{
        opacity: 0;
        padding: 5px;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        font-size: 30px;
        line-height: 30px;
        z-index: 2;
        @include hover{
           text-decoration: none;
        }
    }

    &__title-wrapper{
        width: 80%;
        bottom: 0;
        left: 10%;
        position: fixed;
        z-index: 1000;
        margin: 20px 0;
        text-align: center;
        pointer-events: none;
    }

    
}