@import "../styles/breakpoints";

.project{

    &__title-wrapper{
        position: fixed;
        bottom: 0;
        left: 10%;
        width: 80%;
        text-align: center;
        margin: 20px 0;
        z-index: 999;
    }

    &__info-outer-wrapper{
        width: 100%;
        height: var(--app-height);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 998;
        background-color: #FFF;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.4s ease-in-out;
        &.active{
            opacity: 1;
            pointer-events: all;
        }
        p{
            margin-bottom: 20px;
        }
        strong{
            font-weight: bold;
        }
        em{
            font-style: italic;
        }
        &:before{
            content: '';
            width: 50% ;
            position: absolute;
            height: 25px;
            top: 150px;
            left: 70px;
            z-index: 2;
            background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
            background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
        }
        &:after{
            content: '';
            width: 50%;
            position: absolute;
            height: 25px;
            bottom: 150px;
            left: 70px;
            z-index: 2;
            background: -moz-linear-gradient(bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            background: -webkit-linear-gradient(bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
            background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
        }
    }

    &__info-inner-wrapper{
        width: calc(50% - 50px);
        max-height: calc(var(--app-height) - 300px);
        margin: 150px 0 150px 70px;
        overflow: auto;
        padding-right: 50px;
        position: relative;
    }
    &__info-inner-inner{
        padding: 25px 0;
    }
}

@include mobile{
    .project{

        &__info-outer-wrapper{
            margin: 0;
        }
    
        &__info-inner-wrapper{
            width: calc(100% - 110px);
            max-height: calc(var(--app-height) - 250px);
            margin: 150px 0 100px 50px;
            padding-right: 60px;
            font-size: 12px;
            line-height: 14px;
        }
        &__info-inner-inner{
            padding: 25px 0;
        }
    }

  }