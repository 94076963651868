@import "../styles/breakpoints";

.info{

    &__outer-wrapper{
        padding: 150px 0;
        width: 70%;
        margin: 0 15%;
        min-height: calc(var(--app-height) - 300px);
        text-align: center;
        p{
            margin-bottom: 20px;
        }
        strong{
            font-weight: bold;
        }
        em{
            font-style: italic;
        }
    }

    &__socials{
        display: block;
        margin-bottom: 20px;
    }
}

@include mobile{
    .info{

        &__outer-wrapper{
            padding: 200px 0 100px 0;
            width: calc(100% - 120px);
            margin: 0 60px;
            min-height: calc(var(--app-height) - 300px);
        }
    }
}