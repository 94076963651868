.home-mobile{

    &__outer-wrapper{
        padding: 35px;
    }

    &__title{
        margin: 5px 0 30px 0;
        text-align: center;
    }
}