// --------------------------------------------------------------
// Animations
// --------------------------------------------------------------

@keyframes plyr-progress {
  to {
    background-position: $plyr-progress-loading-size 0;
  }
}

@keyframes plyr-popup {
  0% {
    opacity: 0.5;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes plyr-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
