@import "../../styles/breakpoints";

.logo {
  &__wrapper {
    width: 100%;
    height: var(--app-height);
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  &__link {
    position: fixed;
    height: 10%;
    width: 10vw;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
  }
}

@include mobile{
  .logo {
    &__link {
      height: 12%;
      width: 20vw;
      top: 10px;
      left: 50%;
    }
  }

}